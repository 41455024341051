<template>
  <div>
    <v-card flat style="background-color: var(--v-background-base) !important">
      <v-toolbar
        color="greyBase"
        dark
        :extended="!$vuetify.breakpoint.mobile"
        flat
        extension-height="55"
      >
        <v-toolbar-title>
          <b class="mr-3">Overview</b>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn-toggle
          mandatory
          outlined
          :light="!$store.state.darkMode"
          v-model="toggleView"
          rounded
          color="blue"
        >
          <v-btn small outlined>
            <v-icon left small :color="toggleView == 0 ? 'blue' : 'primaryText'"
              >verified</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)">Shipments</span>
          </v-btn>
          <v-btn small outlined>
            <v-icon left small :color="toggleView == 1 ? 'blue' : 'primaryText'"
              >confirmation_number</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)">Bookings</span>
          </v-btn>
          <v-btn small outlined>
            <v-icon small left :color="toggleView == 2 ? 'blue' : 'primaryText'">
              widgets
            </v-icon>
            <span style="text-transform: none; color: var(--v-primaryText-base)">Containers</span>
          </v-btn>
        </v-btn-toggle>
      </v-toolbar>

      <v-card
        class="mx-auto"
        :max-width="!$vuetify.breakpoint.mobile ? '96vw' : '100%'"
        :style="{
          'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '0px',
          'border-radius': !$vuetify.breakpoint.mobile ? '25px' : '0px',
        }"
      >
        <v-card-text 
          :style="{
            height: !$vuetify.breakpoint.mobile ? '93.5vh' : 'calc(100dvh - 56px - 48px)',
            'overflow-y': 'auto',
          }"
        >
        <v-expand-transition>
            <div v-if="toggleView == 0">
              <Shipments />
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div v-if="toggleView == 1">
              <Bookings />
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div v-if="toggleView == 2">
              <Containers />
            </div>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-card>

  </div>
</template>
  
<script>
import Shipments from '@/components/ShipmentOverview/Shipments.vue'
import Bookings from '@/components/ShipmentOverview/Bookings.vue'
import Containers from '@/components/ShipmentOverview/Containers.vue'
export default {
  components: {
    Shipments,
    Bookings,
    Containers
  },
  data: () => ({
    toggleView: 0
  })
}
</script>



















































